<template>
  <div>
    <div class="box box-default">
      <div class="box-body text-center">
        <h3 class="mb-24">Not authorized</h3>

        <router-link :to="{ name: 'dashboard' }" tag="a"
          class="btn btn-primary btn-flat">Back to Dashboard</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotAuthorized",
};
</script>

<style scoped></style>
